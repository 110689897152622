<script lang="ts">
  import Contact from "./components/Contact.svelte";

  import Footer from "./components/Footer.svelte";
  import GetStarted from "./components/GetStarted.svelte";
  import Header from "./components/Header.svelte";
  import HowItWorks from "./components/HowItWorks.svelte";
  import Intro from "./components/Intro.svelte";
  import Preview from "./components/Preview.svelte";
  import Pricing from "./components/Pricing.svelte";
</script>

<main>
  <Header />

  <Intro />

  <Preview />

  <HowItWorks />

  <Pricing />
  
  <GetStarted />

  <Contact />

  <Footer />
</main>
