<script lang="ts">
  import { platformUrl } from "../variables";
</script>

<header>
  <a href="/"><img src="images/sekreterare_logo.png" alt="logo" /></a>

  <div class="hamburger">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-list"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
      />
    </svg>
  </div>

  <nav>
    <div class="links">
      <a href="#hur-det-fungerar">Hur det funkar</a>
      <a href="#prisplaner">Prisplaner</a>
      <a href="#kontakta-oss">Kontakta oss</a>
    </div>
    <a href={platformUrl} class="button">Logga in</a>
    <a href={platformUrl + "registrera"} class="button button--secondary">
      Skapa konto
    </a>
  </nav>
</header>

<style lang="scss">
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 36px;
    margin: 0 auto;
    max-width: 1620px;
    @media (max-width: 768px) {
      padding: 24px 12px;
    }
  }

  img {
    width: 100%;
    max-width: 200px;
  }

  nav {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 15px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .links {
    display: flex;
    gap: 46px;
    margin-right: 26px;
  }

  .hamburger {
    display: none;

    svg {
      width: 28px;
      height: 28px;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }
</style>
