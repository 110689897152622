<section id="kontakta-oss">
  <article>
    <h2>Kontakta oss</h2>

    <!-- Begin Mailchimp Signup Form -->
    <link
      href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css"
      rel="stylesheet"
      type="text/css"
    />

    <div id="mc_embed_signup">
      <form
        action="https://sekreterare.us14.list-manage.com/subscribe/post?u=628464ae71a5b3281d1755d57&amp;id=c8f77aaad2"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div id="mc_embed_signup_scroll">
          <h2>
            Är du nyfiken på hur er verksamhet kan använda sekreterare.nu?
          </h2>
          <div class="mc-field-group">
            <label for="mce-EMAIL"
              >E-postadress <span class="asterisk">*</span>
            </label>
            <input
              type="email"
              value=""
              name="EMAIL"
              class="required email"
              id="mce-EMAIL"
              placeholder="Skriv din e-postadress"
            />
          </div>
          <div>
            <input type="hidden" name="tags" value="7229693" />
          </div>
          <!-- <div id="mce-responses" class="clear foot">
                  <div
                    class="response"
                    id="mce-error-response"
                    style="display:none"
                  />
                  <div
                    class="response"
                    id="mce-success-response"
                    style="display:none"
                  />
                </div> -->
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input
              type="text"
              name="b_628464ae71a5b3281d1755d57_c8f77aaad2"
              tabindex="-1"
              value=""
            />
          </div>
          <div class="optionalParent">
            <div class="clear foot">
              <input
                type="submit"
                value="Kontakta oss"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <script
      type="text/javascript"
      src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
    <script type="text/javascript">
      (function ($) {
        window.fnames = new Array();
        window.ftypes = new Array();
        fnames[0] = "EMAIL";
        ftypes[0] = "email";
        fnames[1] = "FNAME";
        ftypes[1] = "text";
        fnames[2] = "LNAME";
        ftypes[2] = "text";
        fnames[3] = "ADDRESS";
        ftypes[3] = "address";
        fnames[4] = "PHONE";
        ftypes[4] = "phone";
        fnames[5] = "BIRTHDAY";
        ftypes[5] = "birthday";
      })(jQuery);
      var $mcj = jQuery.noConflict(true);
    </script>
    <!--End mc_embed_signup-->
  </article>
</section>

<style lang="scss">
  article {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h2 {
    text-align: center;
    padding-bottom: 20px;
  }

  #mc_embed_signup h2 {
    font-weight: 600;
    color: black;
  }

  #mc_embed_signup {
    background: #fff0f0;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
    color: black;
  }

  #mc_embed_signup form {
    padding: 30px;
  }

  #mc_embed_signup input,
  #mc_embed_signup input:focus {
    border: none;
  }

  #mc_embed_signup .foot {
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  #mc-embedded-subscribe.button {
    width: 150px;
    background: #cf475b;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    height: auto;
    color: white;
    padding: 10px 0;
    border-radius: 5px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
</style>
