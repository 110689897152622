<article>
  <div>
    <h1>Alla protokoll samlade på en plats</h1>
    <p>
      Var inte bunden av lokal lagring och tidigare dokument för ditt arbete.
      Sekreterare.nu samlar redigering, export och delning av protokollet på en
      och samma plats som är tillgänglig för dig överallt, alltid.
    </p>
    <a href="#hur-det-fungerar" class="button button--secondary"> Läs mer </a>
  </div>

  <img src="images/graphic.png" alt="intro" style="max-width: 500px;" />
</article>

<style lang="scss">
  article {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 140px;

    div:first-child {
      max-width: 600px;
      position: relative;
      z-index: 1;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  img {
    width: 100%;
    max-width: 500px;
    position: relative;
    z-index: 0;

    @media (max-width: 768px) {
      transform: translateY(-120px);
      margin-bottom: -120px;
    }
  }

  h1 {
    font-size: 50px;
  }

  p {
    color: #75787e;
    font-size: 14px;
    padding: 20px 0;
    line-height: 2;
  }
</style>
