<section>
  <article>
    <img src="images/sekreterare_laptop_scaled.png" alt="laptop" />
    <div>
      <h2>
        Sköt mötesplanering och protokollskrivandet enkelt med smarta funktioner
      </h2>

      <ul>
        <li>
          <div class="dot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </div>
          Utgå från färdiga mallar
        </li>
        <li>
          <div class="dot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </div>
          Smidig delning av protokoll
        </li>
        <li>
          <div class="dot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </div>
          Lika säkert som enkelt
        </li>
      </ul>
    </div>
  </article>
</section>

<style lang="scss">
  section {
    background: linear-gradient(92.92deg, #cb3d52 12.28%, #fa1941 82.88%);
    transform: skew(0, 5deg);
    padding: 100px 0;
    margin-bottom: -5vw;

    @media (max-width: 768px) {
      padding: 80px 0 40px;
    }
  }

  article {
    transform: skew(0, -5deg);
    display: flex;

    @media (max-width: 768px) {
      gap: 20px;
      flex-direction: column;
    }
  }

  h2 {
    color: white;
    font-size: 30px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: #ffffff;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    list-style: none;
  }

  li {
    padding: 14px 70px 14px 14px;
    background: #ff274e;
    width: max-content;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 14px;
  }

  img {
    width: 100%;
    max-width: 700px;
  }

  .dot {
    display: grid;
    place-items: center;
    color: #000;
    background: #fff;
    width: 28px;
    height: 28px;
    border-radius: 50%;

    svg {
      width: 20px;
      height: 20px;
    }
  }
</style>
