<section>
  <article id="hur-det-fungerar">
    <h2 style="text-align: center;">Hur det funkar</h2>

    <div class="cards">
      <div class="card">
        <div style="color: black; font-weight: 600;">Steg 1.</div>
        Fyll i det väsentliga, sekreterare.nu sköter resten. Med en klar och standardiserad
        struktur för olika sorters möten blir protokollen klara snabbare och redo
        att justeras direkt efter mötet.
      </div>
      <div class="card">
        <div style="color: black; font-weight: 600;">Steg 2.</div>
        Skapa en organisation och lägg till medlemmarna. Börja sedan planera ett
        möte genom att skapa en dagordning och lägg till eventuella bilagor. Skicka
        en kallelse till medlemmarna med all information om mötet.
      </div>
      <div class="card">
        <div style="color: black; font-weight: 600;">Steg 3.</div>
        Generera enkelt ett mötesprotokoll baserat på dagordningen, använd förifyllda
        texter från mötesmallarna och komplettera med det som beslutas på mötet.
        Förhandsgranska protokollet direkt i webbläsare och dela det enkelt sedan
        via e-post.
      </div>
    </div>
  </article>
</section>

<style lang="scss">
  section {
    background: #fff0f0;
  }

  article {
    padding: 10vw 12px 14vw;
  }

  h2 {
    padding: 30px 0;
  }

  .cards {
    display: grid;
    grid-gap: 28px;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1200px;
    margin: auto;
    justify-content: center;
    font-size: 14px;
    line-height: 1.6;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .card {
    padding: 30px;
    background: white;
    border-radius: 10px;
  }
</style>
