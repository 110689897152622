<script>
  let cards = [
    {
      title: "Basic",
      description:
        "Vår gratisversion av sekreterare.nu räcker långt för den som vill testa tjänsten.",
      price: "Gratis",
      bulletPoints: [
        "Skapa möteskallelser och dagordningar",
        "Mallar för olika typer av protokoll",
        "Digital signatur av protokoll",
      ],
    },
    {
      title: "Premium",
      description:
        "Med vår premiumplan får du tillgång till alla funktioner av sekreterare.nu.",
      price: "199 kr",
      bulletPoints: [
        "Allting från Basic",
        "Egen logotyp på protokoll",
        "Ingen vattenstämpel på protokoll",
        "Digital signatur med kryptering",
        "Prioriterad support",
      ],
    },
    {
      title: "Enterprise",
      description:
        "För den stora organisationen som behöver individuella anpassningar.",
      price: "",
      bulletPoints: [
        "Allting från Basic och Premium",
        "Integrering med era befintliga tjänster och system",
        "White labeling av plattformen",
      ],
    },
  ];
</script>

<article id="prisplaner">
  <div class="pre">
    <h1>Prisplaner</h1>
    <p>
      I våra prisplaner ingår allt från digitala signaturer till prioriterad
      support, nya funktioner och integrationer. Med våra betalplaner kan du ta
      del av ännu fler smidiga funktioner för att förenkla möteshanteringen för
      er organisation.
    </p>
  </div>

  <div class="cards">
    {#each cards as card, i}
      <div class="card">
        <div class="title">{card.title}</div>
        <p>
          {card.description}
        </p>
        <div class="price">
          {#if card.title == "Basic"}
            <h2>{card.price}</h2>
          {:else if card.title == "Enterprise"}
            <h2>Kontakta oss</h2>
          {:else}
            <h2>{card.price}</h2>
            <span>/ månad</span>
          {/if}
        </div>

        <ul>
          {#each card.bulletPoints as bulletPoint, i}
            <li>
              <div class="dot">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                  />
                </svg>
              </div>
              <span>{bulletPoint}</span>
            </li>
          {/each}
        </ul>
      </div>
    {/each}
  </div>
</article>

<style lang="scss">
  article {
    margin: -10vw auto -5vw;
  }

  .pre {
    text-align: center;
    padding: 80px 0 200px;
    background: #222 url("/images/pricing-pattern.svg");
    border-radius: 20px;
  }

  p {
    max-width: 800px;
    color: #9296a0;
    font-size: 14px;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
  }

  h1 {
    color: white;
  }

  .title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #3f333f;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    span {
      font-size: 12px;
      opacity: 0.8;
    }
  }

  .cards {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1000px;
    margin: 0 auto;
    transform: translateY(-200px);

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .card {
    background: #fff;
    border: 1px solid rgba(146, 150, 160, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;
    height: fit-content;

    &:nth-child(2) {
      color: #fff;
      background: linear-gradient(122.62deg, #cb3d52 4.81%, #fa1941 77.13%);
      height: 500px;

      p,
      li span,
      .title,
      .price h2,
      .price span {
        color: white;
      }

      .dot {
        background: white;

        svg {
          fill: #000;
        }
      }
    }

    p {
      font-size: 12px;
      border-bottom: 1px solid #eee;
      margin-bottom: 12px;
    }
  }

  .dot {
    display: grid;
    place-items: center;
    color: #000;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: rgba(206, 59, 82, 0.1);

    svg {
      width: 20px;
      height: 20px;
      fill: #ce3b52;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 0;
    list-style: none;
    font-size: 12px;
  }

  li {
    display: flex;
    gap: 14px;
    align-items: center;

    span {
      flex: 1;
      line-height: 20px;
    }
  }
</style>
