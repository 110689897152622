<script>
  import { platformUrl } from "../variables";
</script>

<section>
  <article>
    <h2>Kom igång med sekreterare.nu</h2>
    <p>
      Tryck här för att skapa ett konto och underlätta din roll som sekreterare
      med digitala protokoll. Sekreterare.nu befinner sig i en spännande
      utvecklingsfas som är öppen för dig och dina medarbetare utan kostnad.
      Självklart erbjuder vi support och svarar på dina frågor.
    </p>
    <div class="buttons">
      <a href={platformUrl} class="button">Logga in</a>
      <a href={platformUrl + "registrera"} class="button button--secondary">
        Skapa konto
      </a>
    </div>
  </article>
</section>

<style lang="scss">
  section {
    background: linear-gradient(180deg, #cb3d52 12.46%, #ed264b 84.13%);
    color: white;
    text-align: center;
    padding: 20px;
  }

  article {
    max-width: 800px;
    min-height: 400px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h2 {
    color: #fff;
    font-size: 34px;
  }

  p {
    font-size: 14px;
  }

  .buttons {
    display: flex;
    gap: 20px;
    padding: 30px 0;
  }

  a:first-child {
    color: #fff;
    border-color: #fff;
  }

  a:last-child {
    background: #fff;
    color: #000;
  }
</style>
